import { template as template_69a3c2f91adb4f6e8b309f2562af3a1a } from "@ember/template-compiler";
const FKText = template_69a3c2f91adb4f6e8b309f2562af3a1a(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
