import { template as template_42cc6de1acf0475f9ad7419b586a3315 } from "@ember/template-compiler";
import concatClass from "discourse/helpers/concat-class";
const FKSection = template_42cc6de1acf0475f9ad7419b586a3315(`
  <div class={{concatClass "form-kit__section" @class}} ...attributes>
    {{#if @title}}
      <h2 class="form-kit__section-title">{{@title}}</h2>
    {{/if}}

    {{#if @subtitle}}
      <span class="form-kit__section-subtitle">{{@subtitle}}</span>
    {{/if}}

    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKSection;
